<template>
    <div>
        <page-header
            :title="$t('pages.payItForward.title')"
            :show-close="true"
            @onClose="returnToRoot"
        />
        <loading-indicator
            v-if="loading"
            :title="$t('global.loadingMessage.loading')"
        />
        <error-view
            v-if="errorText"
            :description="errorText"
            :cta="$t('global.cta.retry')"
            @click="getShareLink"
        />
        <div
            v-else
            class="container text-center"
        >
            <div>
                <img
                    :src="require(`../assets/images/pages/card/${payItForwardMaxAmount}-dollars.svg`)"
                    alt="No current cash outs"
                    class="m-auto"
                >
                <h5 class="text-center fw-light mt-3 mb-1">
                    {{ $t('pages.payItForward.altTitle') }}
                </h5>
                <p
                    class="text-center fw-light mb-5"
                    v-html="$t('pages.payItForward.description', { maxAmount: toFormattedUSDNoCents(this.payItForwardMaxAmount) })"
                />
                <list-row
                    row-icon="images/pages/card/MessageBubble.svg"
                    :title="$t('pages.payItForward.options.textMsg')"
                    :show-arrow="true"
                    @onClick="shareViaText"
                />
                <!-- TODO: currently hide email option for webview until its supported -->
                <list-row
                    v-if="!isWebView"
                    row-icon="images/pages/card/Envelope.svg"
                    :title="$t('pages.payItForward.options.email')"
                    :show-arrow="true"
                    @onClick="shareViaEmail"
                />
                <list-row
                    row-icon="images/pages/card/Link.svg"
                    :title="$t('pages.payItForward.options.copyLink')"
                    :value="isLinkCopied ? '✓ Copied' : ''"
                    :value-class="[{ 'text-success': isLinkCopied }]"
                    :show-arrow="true"
                    @onClick="copyLink"
                />
                <list-row
                    row-icon="images/pages/card/Dots.svg"
                    :title="$t('pages.payItForward.options.moreOptions')"
                    :show-arrow="true"
                    v-if="isWebView"
                    @onClick="openNativeOptions"
                />
                <p
                    class="small text-muted my-3"
                    v-html="$t('pages.payItForward.terms')"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import PageHeader from '@/components/PageHeader'
    import format from '@/mixins/format'
    import { i18n } from '@/utils/i18n'
    import ErrorView from '@/components/ErrorView'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { navigation } from '@/mixins/navigation'
    import ListRow from '@/components/ListRow'
    import { pifNativeShareUrlString } from '@/utils/sharedLogic'
    import { isIOS } from '@/utils/parseUserAgents'
    import { logger } from '@/utils/logger'

    export default {
        name: 'PayItForward',
        components: {
            LoadingIndicator,
            PageHeader,
            ListRow,
            ErrorView,
        },
        mixins: [format, navigation], //returnToRoot comes from navigation
        data: function () {
            return {
                isLinkCopied: false,
                isIOS: isIOS(),
            }
        },
        computed: {
            ...mapGetters(['isWebView', 'payItForwardShareLink', 'payItForwardMaxAmount', 'error', 'loading', 'deviceInfo']),
            smsMessage: function () {
                const temp = this.$t('pages.payItForward.smsMessage', { maxAmount: this.toFormattedUSDNoCents(this.payItForwardMaxAmount), pifLink: this.payItForwardShareLink }).toString()
                return temp
            },
            errorText: function () {
                if (this.error) {
                    return i18n.t('pages.payItForward.getShareLinkError')
                }
                return null
            },
            emailBody: function () {
                return i18n
                    .t('email.sharePifLink.body', {
                        maxAmount: this.toFormattedUSDNoCents(this.payItForwardMaxAmount),
                        pifLink: this.payItForwardShareLink,
                    })
                    .toString()
            },
            emailSubject: function () {
                return i18n
                    .t('email.sharePifLink.subject', {
                        maxAmount: this.toFormattedUSDNoCents(this.payItForwardMaxAmount),
                    })
                    .toString()
            },
            encodedEmailSubject: function () {
                return encodeURI(this.emailSubject)
            },
            encodedEmailBody: function () {
                return encodeURI(this.emailBody)
            },
        },
        mounted() {
            this.$logEvent('view_pay_it_forward_link')
            this.getShareLink()
        },
        methods: {
            getShareLink: async function () {
                if (this.payItForwardShareLink.length === 0) {
                    await this.$store.dispatch('getPayItForward')
                }
            },
            copyLink: function () {
                this.copyToClipboard(this.payItForwardShareLink)
            },
            copyToClipboard: function (str) {
                const el = document.createElement('textarea')
                el.value = str
                document.body.appendChild(el)
                el.select()
                document.execCommand('copy')
                document.body.removeChild(el)
                this.isLinkCopied = true
                setTimeout(() => (this.isLinkCopied = false), 2000)
                this.$logEvent('event_pif_copied_link')
            },
            openNativeOptions: function () {
                window.location.href = pifNativeShareUrlString(this.payItForwardShareLink, this.payItForwardMaxAmount)
            },
            shareViaEmail: function () {
                // TODO: app currently does not support email. waiting for update, but currently this option is only available on desktop.
                window.location.href = this.isWebView
                    ? encodeURI(`mailto://?subject=${this.encodedEmailSubject}&amp;body=${this.encodedEmailBody}`)
                    : `mailto:?subject=${this.emailSubject}&body=${this.emailBody}`
            },
            shareViaText: function () {
                console.log('isIOS', this.isIOS)
                const body = encodeURIComponent(this.smsMessage)
                let url
                if (this.deviceInfo.runtimeType === 'ios') {
                    url = `sms://&body=${body}`
                } else if (this.deviceInfo.runtimeType === 'android') {
                    url = `sms:?body=${body}`
                }
                logger.info(`Redirecting to ${url}`)
                window.location.href = url
            },
        },
    }
</script>

<style lang="scss" scoped>
    .list-row {
        margin: 0;
    }
    .link-copied {
        border: 1px solid;
    }
</style>
